<template>
  <layout :title="$route.name" :toolbar_off="true">
    <v-flex xs12 class="pa-3 pb-0">
      <div class="expande-horizontal centraliza">
        <div class="expande-horizontal column title-item">
          <v-avatar
            @click="$router.go(-1)"
            size="36"
            style="cursor: pointer;"
            color="#e5e5e5"
            icon
          >
            <v-icon color="#000" size="26">mdi-arrow-left</v-icon>
          </v-avatar>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center; height: 40px;"
        >
          <v-icon color="#666" size="38" style="padding-bottom: 3px;"
            >mdi-cash-fast</v-icon
          >
          <span style="font-size: 19pt; text-wrap: nowrap;" class="fonte-bold">
            Depósito
          </span>
        </div>
      </div>
    </v-flex>
    <v-window touchless v-model="step">
      <v-window-item value="1">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                Como deseja depositar
              </span>
            </v-flex>
            <v-flex xs12>
              <v-list class="pa-0 ma-0" color="transparent">
                <v-list-item
                  style="border: 1px solid #ececec; margin-bottom: 12px; border-radius: 6px; "
                  @click="choiseDepositType('bankslip')"
                >
                  <v-list-item-content>
                    <v-list-item-title class="fonte-bold">
                      Boleto
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte"
                      >Será gerado um boleto</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  disabled
                  v-if="false"
                  style="border: 1px solid #ececec; border-radius: 6px; "
                  @click="choiseDepositType('pix')"
                >
                  <v-list-item-content>
                    <v-list-item-title class="fonte-bold">
                      Pix
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte"
                      >Será gerado um código pix para
                      pagamento</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <!-- <v-icon>mdi-chevron-right</v-icon> -->
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>

      <v-window-item value="2">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex xs12>
              <v-list class="fonte" color="transparent">
                <v-list-item v-if="false" class="px-0">
                  <v-avatar color="#333" rounded class="mr-2">
                    <v-icon color="#f2f2f2">mdi-account-circle-outline</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Destino
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-avatar color="#333" rounded class="mr-2">
                    <v-icon color="#f2f2f2">mdi-cash</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ type === "bankslip" ? "Boleto" : "Pix" }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Método de pagamento
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                Valor do depósito
              </span>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                filled
                color="black"
                rounded
                min="10"
                prefix="R$"
                v-mask="[
                  '#,##',
                  '##,##',
                  '###,##',
                  '####,##',
                  '##.###,##',
                  '###.###,##'
                ]"
                :rules="[
                  value =>
                    parseFloat(value.replace(',', '.')) >= 10 ||
                    'O valor deve ser maior que R$10,00'
                ]"
                large
                v-model="value"
                autofocus
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal column centraliza">
                <v-btn
                  @click="request"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Solicitar</v-btn
                >
                <v-btn
                  class="mt-3"
                  @click="step = '1'"
                  :loading="loading"
                  small
                  color="grey"
                  dark
                  text
                  >Cancelar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>
      <v-window-item value="3">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span
                class="fonte-bold mb-6 expande-horizontal centraliza"
                style="font-size: 24pt;"
              >
                <v-avatar size="120" color="green">
                  <v-icon color="white" size="100">mdi-check</v-icon>
                </v-avatar>
              </span>
            </v-flex>
            <v-flex class="px-1" xs12>
              <div class="expande-horizontal centraliza column">
                <span class="fonte-bold" style="font-size: 24pt;">
                  Boleto gerado com sucesso
                </span>
                <span class="fonte grey--text mb-2" style="font-size: 12pt;">
                  Visualize ou faça o download abaixo
                </span>
              </div>
            </v-flex>
            <v-flex class="px-1" xs12>
              <div
                style="width: 100%"
                class="expande-horizontal column mb-2 pb-1 pt-0"
              >
                <iframe
                  height="500"
                  :src="res.bankSlipUrl"
                  frameborder="0"
                ></iframe>
                <button
                  class="btn2 ml-1 mt-2"
                  icon
                  v-if="false"
                  @click="
                    createGlobalMessage({
                      type: 'success',
                      message: 'Número do boleto copiado com sucesso!',
                      timeout: 3000
                    })
                  "
                  data-clipboard-target="#foo"
                  style="background-color: #333;border-radius: 6px;min-width: 40px; min-height: 40px; max-height: 40px;"
                >
                  <span class="fonte-bold white--text">
                    Clique baixar o boleto
                  </span>
                  <v-icon color="white">mdi-download</v-icon>
                </button>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>
    </v-window>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClipboardJS from "clipboard";

export default {
  props: ["transaction_type"],
  data() {
    return {
      type: "",
      value: "",
      step: "1",
      loading: false,
      myLink: "",
      res: {}
    };
  },
  methods: {
    ...mapActions(["requestDeposit", "createGlobalMessage"]),
    choiseDepositType(type) {
      this.type = type;
      this.step = "2";
    },
    request() {
      if (parseFloat(this.value.replace(",", ".")) < 10) {
        alert("O valor deve ser 10 reais ou maior");
        return;
      }
      this.requestDeposit({
        value: this.value,
        type: this.type
      }).then(res => {
        if (this.type == "bankslip") {
          this.step = "3";
          let boleto = res.data.external_data ? res.data.external_data.res : {};
          this.res = boleto;
          this.myLink = `${boleto.Data.LinhaDigitavel}`;
          new ClipboardJS(".btn2");
        }
        if (this.type == "pix") {
          this.step = "4";
        }
      });
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  }
};
</script>
